import React from "react";
import { Helmet } from "react-helmet";
import tenderDocument from "../Documents/Tender-Notice-for-Website.pdf";
import rfpdocument from "../Documents/RFP-004-For-Website-Doc.pdf";
import { motion as m } from "framer-motion";
import Button from "react-bootstrap/Button";

const TenderNotice = () => {
  return (
    <m.div
      initial={{ scaleY: 0 }}
      animate={{ scaleY: 1 }}
      exit={{ scaleY: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Helmet>
        <title>Tender Notice |</title>
      </Helmet>
      <section id="bsdisciplinebg" className="bgimagesetting">
        <h1 className="">Tender Notice</h1>
        <div id="overlay"></div>
      </section>
      <div className="container">
        <div className="row mtb">
          <a href={rfpdocument} target="_blank" rel="noreferrer">
            <Button className="Contact-btn read-btn">
              Request for Proposal Document
            </Button>{" "}
          </a>
        </div>
        <div className="row mtb">
          <a href={tenderDocument} target="_blank" rel="noreferrer">
            <Button className="Contact-btn read-btn">Tender Notice</Button>{" "}
          </a>
        </div>
      </div>
    </m.div>
  );
};

export default TenderNotice;
