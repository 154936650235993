import React from "react";
import { Helmet } from "react-helmet";
import { motion as m } from "framer-motion";

const Gallery = () => {
    return (
        <m.div
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            exit={{ scaleY: 0 }}
            transition={{ duration: 0.5 }}
        >
            <Helmet>
                <title>Gallery |</title>
            </Helmet>
            <section id="aboutus" className="bgimagesetting">
                <h1 className="" data-aos="zoom-in">GALLERY</h1>
                <div id="overlay"></div>
            </section>
            <section id="Gallery">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/islamic_finance_Meezan.jpg"}
                                className="w-100"
                                alt="" />
                            
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/event2.jpeg"}
                                className="w-100 mt-40"
                                alt="" />
                        </div>
                        <div className="col-md-4">
                        <video autoPlay loop muted playsInline className="back-video w-100" >
                              <source src={'/assets/videos/World_Suicide_Presentation_Day.mp4'} type="video/mp4" />
                            </video>
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/event1.jpeg"}
                                className="w-100"
                                alt="" />

                        </div>

                        <div className="col-md-4">
                        <img
                                src={process.env.PUBLIC_URL + "/assets/images/event11.jpg"}
                                className="w-100 "
                                alt="" />
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/event12.jpg"}
                                className="w-100 mt-40"
                                alt="" />
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/event21.jpg"}
                                className="w-100 mt-40"
                                alt="" />
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/event22.jpg"}
                                className="w-100 mt-40"
                                alt="" />
                        </div>
                    </div>
                    <div className="row mt-40">
                        {/* <div className="col-md-4">
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/event2.jpeg"}
                                className="w-100"
                                alt="" />
                        </div> */}
                        {/* <div className="col-md-4">
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/event21.jpg"}
                                className="w-100"
                                alt="" />
                        </div> */}

                        {/* <div className="col-md-4">
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/event22.jpg"}
                                className="w-100"
                                alt="" />
                        </div> */}
                    </div>

                </div>
            </section>
        </m.div>
    );
}

export default Gallery;