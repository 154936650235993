import React,{useState, useEffect} from "react";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Container, Nav, Navbar } from "react-bootstrap";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaWhatsapp} from "react-icons/fa";

const Navcomponent = () => {
  const [show, setShow] = useState(false);
  const [showadmin, setShowAdmin] = useState(false);
  const [showacademic, setShowAcademic] = useState(false);
  const showDropdown = (e) => {
    setShow(!show); 
  }
  const hideDropdown = e => {
    setShow(false);
  }
  const showDropdownadmin = (e) => {
    setShowAdmin(!showadmin);
  }
  const hideDropdownadmin = e => {
    setShowAdmin(false);
  }

  const showDropdownacademic = (e) => {
    setShowAcademic(!showadmin);
  }
  const hideDropdownacademic = e => {
    setShowAcademic(false);
  }
  // const hidenav = () => {
  //   document.body.style.overflow = "visible";
  //   const check = document.querySelector(".offcanvas");
  //   check.style.opacity = 0;
  //   check.style.zIndex = -1;
  // };
  useEffect(() => {
    var backtoTop = document.getElementById("back-to-top");
    window.addEventListener("scroll", function () {
      if (window.scrollY > 4100) {
        backtoTop.classList.add("active");
      } else {
        backtoTop.classList.remove("active");
      }
      backtoTop.style.transform = "rotate(" + window.pageYOffset + "deg)";
    });
  }, []);
  return (
    <>
      {[false].map((expand) => (
        <>
          <section id="dailynews">
            <span className="news-flag">
              NEWS
            </span>
            <marquee onMouseOver="this.stop();"
              onMouseOut="this.start();" className="top-news">
              Announcement of Admission Schedule for Affiliated Colleges: BS Admissions Closed. Important announcement: Islamic finance workshop has been postponed due to current prevailing situation. New dates will be announced soon. Tender Notice: 
              { <a href="/tender-notice" className="clickanchor">Click here</a> }
              {/* { <a href="https://whatsapp.com/channel/0029Vat95qT3mFY0xxfMq40P" className="clickanchor">Click here</a> } */}
            </marquee>
          </section>
          <section id="top-navbar">
            <Container fluid className="top-mainnav">
              {/* <div className="slogan"><b>Dar<span>e</span> to<span> Dre</span>am</b></div> */}
              <Navbar.Brand href="/"> <img
                src={process.env.PUBLIC_URL + "/assets/images/logonew.png"}
                className="logomain"
                alt="" /></Navbar.Brand>
              <Nav className="top-nav2">
                {/* <div className="call-div">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/phone-call.png"}
                    className="w-20"
                    alt="" />
                  <div className="contact-divtop"> */}
                {/* <p className="font-tomato">Call Now!</p> */}
                {/* <h5 className="font-tomato">+92-51-5910020</h5>

                  </div>
                </div> */}
                {/* <div className="email-div">
                <img
                    src={process.env.PUBLIC_URL + "/assets/images/mailing.png"}
                    className="w-20"
                    alt="" />
                  <div className="contact-divtop"> */}
                {/* <p className="font-tomato">Email Now!</p> */}
                {/* <h5 className="font-tomato">info@nup.edu.pk</h5>

                  </div>
                </div> */}
              </Nav>
              {/* <span className="follow-text tomato">Follow Us:-</span> */}
              <div className="flex-box ms-auto">
                <a href="/careers">Careers</a>
                <a href="/admission-fall-2024">Admissions<sup className="superscript">New</sup></a>
                <a href="/gallery">Gallery</a>
                <NavDropdown title="Downloads" id="basic-nav-dropdown" className="firstnav-dropdown"
                  show={show}
                  onMouseEnter={showDropdown}
                  onMouseLeave={hideDropdown}
                >
                  <NavDropdown.Item href="/charter-of-nup">Charter</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/instruction-on-affiliation">Instructions on Affiliation</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/bs-discipline">Notification of Affiliation</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/college-affiliation-form">College Affiliation Form</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/admission-schedule">Admission Schedule Fall-2024</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/academic-system">Academic System</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/schemes-of-studies">Schemes of Studies</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/Academic-calender">Academic Calender</NavDropdown.Item>
                </NavDropdown>
                <Nav className="top-nav">
                  <a href="https://web.facebook.com/nupakofficial/" className="fb-iconparent" target="_blank"><FaFacebookF className="fb-icon" /></a>
                  <a href="https://whatsapp.com/channel/0029Vat95qT3mFY0xxfMq40P" className="wp-iconparent ms-2" target="_blank"><FaWhatsapp className="wp-icon" /></a>
                  {/* <a href="" ><FaLinkedinIn className="linkedin-icon" /></a> */}
                </Nav>
              </div>

            </Container>
          </section>
          <Navbar expand="lg" className="bg-body-tertiary">
            <Container fluid>
              <Navbar.Brand href="/" className="logomobile"> <img
                src={process.env.PUBLIC_URL + "/assets/images/mainlogo1.png"}
                className="logomain"
                alt="" /></Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                {/* <Nav className="ms-auto"> */}
                <Nav className="">
                  <Nav.Link href="/">Home</Nav.Link>
                  <Nav.Link href="/about-us">About Us</Nav.Link>
                  {/* <Nav.Link href="/charter-of-nup">Charter</Nav.Link> */}
                  {/* <Nav.Link href="/#sacritariat">Secretariat</Nav.Link> */}
                  <NavDropdown title="Administration" id="basic-nav-dropdown" show={showadmin}
                  onMouseEnter={showDropdownadmin}
                  onMouseLeave={hideDropdownadmin}>
                    <NavDropdown.Item href="/rector">Rector</NavDropdown.Item>
                    {/* <NavDropdown.Divider />
                    <NavDropdown.Item href="/registrar">Registrar</NavDropdown.Item> */}
                    {/* <NavDropdown.Divider />
                    <NavDropdown.Item href="/controller-exams">Controller Exams</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/treasurer">Treasurer</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/planning-and-development">Planning & Development</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/directorate">Directorate of Student Affairs</NavDropdown.Item> */}
                  </NavDropdown>

                  <NavDropdown title="Academics" id="basic-nav-dropdown" show={showacademic}
                  onMouseEnter={showDropdownacademic}
                  onMouseLeave={hideDropdownacademic}>
                    <NavDropdown title="Faculty of Computing" className="nested-drop dropdown-fontsmall">
                      <NavDropdown title="Department of Computer Sciences" className="nested-drop dropdown-fontsmall">
                        <NavDropdown.Item href="/department-of-computer-science">Program Offered</NavDropdown.Item>
                        <NavDropdown.Item href="/faculty-of-computer-science">Faculty Profile</NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown title="Department of Information Technology" className="nested-drop dropdown-fontsmall">
                        <NavDropdown.Item href="/department-of-information-technology">Program Offered</NavDropdown.Item>
                        <NavDropdown.Item href="/faculty-of-information-technology">Faculty Profile</NavDropdown.Item>
                      </NavDropdown>
                    </NavDropdown>
                    <NavDropdown title="Faculty of Management Sciences" className="nested-drop dropdown-fontsmall">
                      <NavDropdown title="Department of Management Sciences" className="nested-drop dropdown-fontsmall">
                        <NavDropdown.Item href="/department-of-management-sciences">Program Offered</NavDropdown.Item>
                        <NavDropdown.Item href="/faculty-of-management-sciences">Faculty Profile</NavDropdown.Item>
                      </NavDropdown>
                    </NavDropdown>
                    <NavDropdown title="Faculty of Social Sciences, Humanities & Arts" className="nested-drop dropdown-fontsmall">
                      <NavDropdown title="Department of Psychology" className="nested-drop dropdown-fontsmall">
                        <NavDropdown.Item href="/department-of-psychology">Program Offered</NavDropdown.Item>
                        <NavDropdown.Item href="/faculty-of-psychology">Faculty Profile</NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown title="Department of English" className="nested-drop dropdown-fontsmall">
                        <NavDropdown.Item href="/department-of-english">Program Offered</NavDropdown.Item>
                        <NavDropdown.Item href="/faculty-of-english">Faculty Profile</NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown title="Department of Fine Arts" className="nested-drop dropdown-fontsmall">
                        <NavDropdown.Item href="/department-of-fine-arts">Program Offered</NavDropdown.Item>
                        <NavDropdown.Item href="/faculty-of-fine-arts">Faculty Profile</NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown title="Department of Health & Physical Education" className="nested-drop dropdown-fontsmall">
                        <NavDropdown.Item href="/department-of-physical-education">Program Offered</NavDropdown.Item>
                        <NavDropdown.Item href="/faculty-of-physical-education">Faculty Profile</NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown title="Department of Islamic Studies" className="nested-drop dropdown-fontsmall">
                        <NavDropdown.Item href="/department-of-islamic-studies">Program Offered</NavDropdown.Item>
                        <NavDropdown.Item href="/faculty-of-islamic-studies">Faculty Profile</NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown title="Department of Political Science" className="nested-drop dropdown-fontsmall">
                        <NavDropdown.Item href="/department-of-political-science">Program Offered</NavDropdown.Item>
                        <NavDropdown.Item href="/faculty-of-political-science">Faculty Profile</NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown title="Department of Urdu" className="nested-drop dropdown-fontsmall">
                        <NavDropdown.Item href="/department-of-urdu">Program Offered</NavDropdown.Item>
                        <NavDropdown.Item href="/faculty-of-urdu">Faculty Profile</NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown title="Department of Economics" className="nested-drop dropdown-fontsmall">
                        <NavDropdown.Item href="/department-of-economics">Program Offered</NavDropdown.Item>
                        <NavDropdown.Item href="/faculty-of-economics">Faculty Profile</NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown title="Department of Special Education" className="nested-drop dropdown-fontsmall">
                        <NavDropdown.Item href="/department-of-special-education">Program Offered</NavDropdown.Item>
                        <NavDropdown.Item href="/faculty-of-special-education">Faculty Profile</NavDropdown.Item>
                      </NavDropdown>
                    </NavDropdown>
                    <NavDropdown title="Faculty of Engineering & Emerging Technologies" className="nested-drop dropdown-fontsmall">
                      <NavDropdown title="Department of Engineering & Emerging Technologies" className="nested-drop dropdown-fontsmall">
                        <NavDropdown.Item href="/department-of-emerging-technologies">Program Offered</NavDropdown.Item>
                        <NavDropdown.Item href="/faculty-of-emerging-technologies">Faculty Profile</NavDropdown.Item>
                      </NavDropdown>
                    </NavDropdown>
                    <NavDropdown title="Faculty of Natural & Applied Sciences" className="nested-drop dropdown-fontsmall">
                      <NavDropdown title="Department of Mathematics" className="nested-drop dropdown-fontsmall">
                        <NavDropdown.Item href="/department-of-mathematics">Program Offered</NavDropdown.Item>
                        <NavDropdown.Item href="/faculty-of-mathematics">Faculty Profile</NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown title="Department of Physics" className="nested-drop dropdown-fontsmall">
                        <NavDropdown.Item href="/department-of-physics">Program Offered</NavDropdown.Item>
                        <NavDropdown.Item href="/faculty-of-physics">Faculty Profile</NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown title="Department of Botany" className="nested-drop dropdown-fontsmall">
                        <NavDropdown.Item href="/department-of-botany">Program Offered</NavDropdown.Item>
                        <NavDropdown.Item href="/faculty-of-botany">Faculty Profile</NavDropdown.Item>
                      </NavDropdown>
                    </NavDropdown>
                    
                   
                  </NavDropdown>
                  {/* <Nav.Link href="/admission-fall-2024">Admission Fall 2024</Nav.Link> */}
                  {/* <NavDropdown title="Affiliated Colleges" id="basic-nav-dropdown">
                    <NavDropdown.Item href="/instruction-on-affiliation">Instructions on Affiliation</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/bs-discipline">BS Disciplines of Affiliated Colleges</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/college-affiliation-form">College Affiliation Form</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/admission-schedule">Admission Schedule Fall-2024</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/academic-system">Academic System</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/schemes-of-studies">Schemes of Studies</NavDropdown.Item>
                  </NavDropdown> */}
                  <Nav.Link href="/affiliated-colleges" >Affiliated Colleges</Nav.Link>
                  <Nav.Link href="/contact" className="contact-btn">CONTACT</Nav.Link>
                </Nav>
              </Navbar.Collapse>
              <Nav className="mob-socialmedia">
                  <a href="https://web.facebook.com/nupakofficial/" className="fb-iconparent"><FaFacebookF className="fb-icon" /></a>
                </Nav>
            </Container>
          </Navbar>
        
          </>
      ))}
    </>
  );
};

export default Navcomponent;
